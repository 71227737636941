import {IsDefined, IsInt} from "class-validator";
import {identifier, serializable} from "serializr";

export class OAuthUser {
  @serializable(identifier())
  @IsDefined()
  @IsInt()
  userId: number;

  @serializable
  azureId: number;

  @serializable
  githubId: number;

  @serializable
  googleId: number;
}
