import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {environment} from '../../../../../environments/environment';
import {LoginService} from '../../services/login.service';
import {Subscription} from 'rxjs';
import {LoginFormComponent} from '../../components/login-form/login-form.component';
import {AuthService} from '@tlgpro/security';
import {LocalStorageService} from '@tlgpro/util';
import {Oauth2Service} from "../service/oauth2.service";

@Component({
  template: `
    <div [ngClass]="backgroundClass" class="bg-no-repeat bg-cover h-screen w-screen flex justify-center items-center">
      <section class="basis-full">
        <app-login-form *ngIf="providers" [form]="form" [providers]="providers" (formSubmit)="onSubmit({data : $event})"></app-login-form>
      </section>
    </div>
  `,
  styleUrls: ['./login.page.css']
})
// tslint:disable-next-line:component-class-suffix
export class LoginPage implements OnInit, OnDestroy {

  applicationName = environment.applicationName;
  backgroundClass: string;

  form = this.formBuilder.group({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    remember: new FormControl(false)
  });

  private loading = false;
  public providers : string[];

  // Clé de stockage du username dans le localStorage
  private readonly LOGIN_USERNAME_KEY = 'login.username';

  private loginSubscription: Subscription;
  private oAuthProvidersSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
              private login: LoginService,
              private auth: AuthService,
              private oauth2Service: Oauth2Service,
              private router: Router,
              private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.auth.logout();

    this.oAuthProvidersSubscription = this.oauth2Service?.getOAuthProviders().subscribe((p) => {
      this.providers = p.providers
    })

    this.backgroundClass = `bg-${Math.floor(Math.random() * 5) + 1}`;

    // Récupération du nom d'utilisateur enregistré lors de la dernière connexion
    const username = this.localStorageService.getItem(this.LOGIN_USERNAME_KEY);
    // const username = localStorage.getItem(this.LOGIN_USERNAME_KEY);
    if (username !== null) {
      this.form.setValue({
        username,
        password: '',
        remember: true
      });
    }

  }

  onSubmit({data}: { data: { loginComponent?: LoginFormComponent } }): void {
    const {loginComponent} = data;

    if (this.loading) {
      return;
    }

    if (this.form.invalid && loginComponent) {
      loginComponent.submitted = false;
      return;
    }

    if (loginComponent) {
      loginComponent.connexionDenied = false;
    }

    this.loading = true;

    const {username, password, remember} = this.form.value;

    if (!username || !password) {
      return;
    }

    if (remember) {
      // localStorage.setItem(this.LOGIN_USERNAME_KEY, username);
      this.localStorageService.setItem(this.LOGIN_USERNAME_KEY, username);
    } else {
      // localStorage.removeItem(this.LOGIN_USERNAME_KEY);
      this.localStorageService.removeItem(this.LOGIN_USERNAME_KEY);
    }

    this.loginSubscription = this.login.login({username, password}).subscribe(
      () => {
        if (loginComponent) {
          loginComponent.submitted = false;
        }

        this.router.navigate([environment.router.home]);
        this.loading = false;
      },
      (error) => {

        if (loginComponent) {
          loginComponent.submitted = false;
          loginComponent.connexionDenied = true;
        }

        this.loading = false;

        throw error;
      });
  }

  ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
    this.oAuthProvidersSubscription?.unsubscribe();
  }
}
