import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../../../environments/environment";
import {LoginResponse} from "@tlgpro/security";
import {LoginService} from "../../services/login.service";
import {map} from "rxjs/operators";
import {plainToInstance} from "class-transformer";
@Injectable({
  providedIn: 'root'
})
export class Oauth2Service {
  constructor(private http: HttpClient) {
  }

  public getJwtOAuth(provider: string, params: any): Observable<LoginResponse> {
    return this.http.get<LoginService>(`${environment.api.prefix}${environment.oauth2.url.code}/${provider}`, {params: params}).pipe(
      map((data: unknown) => plainToInstance(LoginResponse, data))
    );
  }

  public getOAuthProviders(): Observable<any> {
    return this.http.get<any>(`${environment.api.prefix}/actuator/oauth2`, {});
  }

}
