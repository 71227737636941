import {AfterViewInit, Component, Inject, Input, LOCALE_ID, OnInit} from '@angular/core';
import {FormValidator, FormValidatorModel} from '@syncfusion/ej2-angular-inputs';
import {OAuthUser} from "../../models/o-auth-user";

@Component({
  selector: 'change-identifiants-sso-form',
  template: `
    <div id="form">
      <div class='form-group e-float-input'>
        <input type='text' name="azureId" [(ngModel)]="aouthUser.azureId" />
        <span class='e-float-line'></span>
        <label class='e-float-text' for='azureId' i18n="@@form.field.azureId">
          Identifiant Microsoft</label>
      </div>

      <div class='form-group e-float-input'>
        <input type='text' name="githubId" [(ngModel)]="aouthUser.githubId" />
        <span class='e-float-line'></span>
        <label class='e-float-text' for='githubId' i18n="@@form.field.githubId">Identifiant GitHub</label>
      </div>

      <div class='form-group e-float-input'>
        <input type='text' name="googleId" [(ngModel)]="aouthUser.googleId" />
        <span class='e-float-line'></span>
        <label class='e-float-text' for='googleId' i18n="@@form.field.googleId">Identifiant Google</label>
      </div>
    </div>

  `,
  styleUrls: ['./change-identifiants-sso-form.component.css']
})
export class ChangeIdentifiantsSsoFormComponent implements OnInit, AfterViewInit {

  private formValidator: FormValidator;

  @Input() aouthUser: OAuthUser;

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    const options: FormValidatorModel = {
      locale: this.locale,
      customPlacement: (element: HTMLElement, error: HTMLElement) => {
        element.closest('.form-group')?.appendChild(error);
      }
    };

    this.formValidator = new FormValidator('#form', options);
  }

  public isValid(): boolean {
    return this.formValidator.validate();
  }

  public getValue(): OAuthUser | undefined {
    return this.aouthUser;
  }

  public reset(): void {
    // delete this.aouthUser;
  }

}
