import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginPage} from './features/authentification/pages/login/login.page';
import {LogoutPage} from './features/authentification/pages/logout/logout.page';
import {LoginOAuth2Page} from "./features/authentification/pages/login-oauth2/login-o-auth2.page";

export const routes: Routes = [
  {path: 'login', component: LoginPage, data: {title: 'Page de connexion'}},
  {path: 'login/oauth2/:provider', component: LoginOAuth2Page, data: {title: 'Page de connexion OAuth2'}},
  {path: 'logout', component: LogoutPage},
  {path: '', loadChildren: () => import('./features/application/application.module').then(m => m.ApplicationModule)}
  /*{path: '', redirectTo: '/config', pathMatch: 'full'},
  {
    path: 'config',
    loadChildren: () => import('./features/node-service/node-service.module').then(m => m.NodeServiceModule)
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
