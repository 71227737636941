import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-login-form',
  template: `
    <section class="">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="shadow-inner divide-y w-full bg-white rounded-lg md:mt-0 sm:max-w-md xl:p-0">
          <div class="m-6 flex justify-center">
            <app-logo-application [loginDisplay]="true" [enableLink]="false"></app-logo-application>
          </div>
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8 overflow-hidden">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="space-y-4 md:space-y-6">
              <div class="group">
                <label for="username"
                       class="block mb-2 text-sm font-medium text-gray-900 group-focus-within:text-blue-600"
                       i18n="@@authentification.login.username">Nom d'utilisateur</label>
                <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <i class="fa-solid fa-user group-focus-within:text-blue-600"></i>
                  </div>
                  <input type="text" id="username" formControlName="username"
                         class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-[10px] pl-10 focus:outline-blue-600"
                         required>
                </div>
              </div>
              <div class="group">
                <label for="password"
                       class="block mb-2 text-sm font-medium text-gray-900 group-focus-within:text-blue-600"
                       i18n="@@authentification.login.password">Mot de passe</label>
                <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <i class="fa-solid fa-key fa-lg group-focus-within:text-blue-600"></i>
                  </div>
                  <input type="password" id="password" formControlName="password"
                         class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-[10px] pl-10 focus:outline-blue-600"
                         required>
                </div>
              </div>
              <div class="w-100 h-100 flex items-center justify-between">
                <div class="flex items-start">
                  <div class="flex items-center h-5">
                    <input id="remember" aria-describedby="remember" type="checkbox" formControlName="remember"
                           class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300">
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="remember" class="text-gray-500"
                           i18n="@@authentification.login.remember">Se souvenir de mon identifiant</label>
                  </div>
                </div>
              </div>
              <button
                      class="z-50 inline-flex justify-center items-center
                      w-full py-2 text-white rounded-lg font-semibold shadow
                      transition-all bg-size-200 bg-pos-0
                      focus:ring-4 focus:outline-none focus:ring-blue-300
                      hover:bg-pos-100 hover:shadow-md"
                      [ngClass]="{'bg-gradient-to-tl from-blue-800 via-blue-600 to-blue-400 duration-300': form.valid,
                                  'cursor-default bg-gray-500 focus:ring-0': !form.valid,
                                  'bg-pos-100 shadow-md': submitted}">
                <i class="fa-solid fa-circle-notch fa-spin fa-lg mr-2"
                   [ngClass]="{'w-0 !hidden': !submitted, 'w-auto block': submitted}"></i><ng-container i18n="@@authentification.login.button">Connexion</ng-container>
              </button>
              <div class="duration-500 transition-[height,opacity]"
                   [ngClass]="{'h-0 hidden !mt-0': !connexionDenied, 'h-full block mt-3': connexionDenied}">
                <div class="flex items-center p-3 bg-red-100 rounded-b-lg border-t-4 border-red-500 text-red-700"
                     role="alert">
                  <i class="fa-solid fa-circle-exclamation"></i>
                  <div class="ml-3 text-sm font-medium">
                    <h3 i18n="@@authentification.login.connectionRefused">Connexion refusée</h3>
                    <p class="font-normal" i18n="@@authentification.login.incorrectCredentials">Les identifiants saisis sont incorrects.</p>
                  </div>
                </div>
              </div>

            </form>

            <h2 *ngIf="providers?.length > 0" ><span>OU</span></h2>
            <div class="space-y-2 md:space-y-2">

              <a *ngIf="providers?.indexOf('azure') !== -1" href="{{environment.api.prefix}}${environment.oauth2.url.authorization}/azure" class="btn-microsoft hover:text-white shadow text-sm font-bold py-3 px-4 rounded-lg flex justify-start items-center cursor-pointer w-full">
                <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" class="fill-current mr-3 w-6 h-6"><path d="M19.852 7.761l-15 2.25C4.362 10.085 4 10.505 4 11v12c0 .553.448 1 1 1h15c.552 0 1-.447 1-1V8.75c0-.291-.127-.567-.348-.758C20.432 7.803 20.139 7.721 19.852 7.761zM45.652 4.242c-.22-.189-.512-.271-.801-.231l-21 3.15C23.362 7.235 23 7.655 23 8.15V23c0 .553.448 1 1 1h21c.552 0 1-.447 1-1V5C46 4.709 45.873 4.433 45.652 4.242zM20 26H5c-.552 0-1 .447-1 1v12c0 .495.362.915.852.989l15 2.25c.05.007.099.011.148.011.238 0 .47-.085.652-.242C20.873 41.817 21 41.541 21 41.25V27C21 26.447 20.552 26 20 26zM45 26H24c-.552 0-1 .447-1 1v14.85c0 .495.362.915.852.989l21 3.15C44.901 45.996 44.951 46 45 46c.238 0 .47-.085.652-.242C45.873 45.567 46 45.291 46 45V27C46 26.447 45.552 26 45 26z"/></svg>
                <span class="border-l border-blue-200 h-6 w-1 block mr-1"></span>
                <span class="pl-3 w-full text-center" i18n="@@authentification.login.oauth.azure.button">Continuer avec Microsoft</span>
              </a>

              <a *ngIf="providers?.indexOf('google') !== -1" href="{{environment.api.prefix}}${environment.oauth2.url.authorization}/google" class="btn-google hover:text-white shadow font-bold text-sm py-3 px-4 rounded-lg flex justify-start items-center cursor-pointer w-full">
                <svg viewBox="0 0 24 24" class="fill-current mr-3 w-6 h-5" xmlns="http://www.w3.org/2000/svg"><path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"/></svg>
                <span class="border-l border-red-300 h-6 w-1 block"></span>
                <span class="pl-3 w-full text-center" i18n="@@authentification.login.oauth.google.button">Continuer avec Google</span>
              </a>

              <a *ngIf="providers?.indexOf('github') !== -1" href="{{environment.api.prefix}}${environment.oauth2.url.authorization}/github" class="btn-github hover:text-white shadow font-bold text-sm py-3 px-4 rounded-lg flex justify-start items-center cursor-pointer w-full">
                <svg viewBox="0 0 24 24" class="fill-current mr-3 w-6 h-6" xmlns="http://www.w3.org/2000/svg"><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/></svg>
                <span class="border-l border-gray-500 h-6 w-1 block mr-1"></span>
                <span class="pl-3 w-full text-center" i18n="@@authentification.login.oauth.github.button">Continuer avec GitHub</span>
              </a>

            </div>
          </div>
          <div class="z-50 h-[40px] flex items-center justify-end pr-3">
            <i>Powered by <a href="https://tlgpro.fr" class="logo-tlgpro hover:underline" target="_blank">TLG
              Pro</a></i>
          </div>
        </div>
      </div>
    </section>
  `,
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent {

  @Input() form: FormGroup;
  @Input() providers: string[];
  @Output() formSubmit = new EventEmitter<{ loginComponent?: LoginFormComponent }>();

  public submitted = false;
  public connexionDenied = false;

  get username(): AbstractControl | null {
    return this.form.get('username');
  }

  get password(): AbstractControl | null {
    return this.form.get('password');
  }

  constructor() {
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.submitted = true;

    this.formSubmit.emit({loginComponent: this});
  }

  protected readonly environment = environment;
}
