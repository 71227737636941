import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService, LoginResponse, RefreshTokenResponse, UserDetailsResponse} from '@tlgpro/security';
import {Observable, Subscription} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy {

  private logoutSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router
  ) {
  }

  login(data: { username: string; password: string }): Observable<UserDetailsResponse> {

    const formData = new FormData();
    formData.append('username', data.username);
    formData.append('password', data.password);

    return this.http
      .post<LoginResponse>(`${environment.api.prefix}/login`, formData)
      .pipe(
        // filter(({ data, error }) => data !== undefined && !error),
        // tap(e => console.log(e)),
        switchMap((response) => LoginResponse.deserialize(response)),
        // tap(e => console.log(e)),
        switchMap((response) => this.loginSuccessHandler(response)),

        // switchMap(() => this.http.get<UserDetailsResponse>(`${environment.api.prefix}/auth/currentUserDetails`)),
        // switchMap((response) => UserDetailsResponse.deserialize(response)),
        // tap((response) => {
        //   this.auth.setUser(response.user);
        // }),
      );

  }

  loginSuccessHandler(response: LoginResponse): Observable<UserDetailsResponse>{
    // Save JWT
    this.auth.login(response.token);

    // Get User Details
    return this.http.get<UserDetailsResponse>(`${environment.api.prefix}/auth/currentUserDetails`)
      .pipe(
        switchMap((userDetails) => UserDetailsResponse.deserialize(userDetails)),
        tap((userDetailsResponse: UserDetailsResponse) => {this.auth.setUser(userDetailsResponse.user);})
      );
  }

  //TODO
  loginOAuth(token: string): any {
    this.auth.login(token);
    this.http.get<UserDetailsResponse>(`${environment.api.prefix}/auth/currentUserDetails`).subscribe(response => {
      UserDetailsResponse.deserialize(response);
      this.auth.setUser(response.user);
    });
  }

  logout(): void {

    this.logoutSubscription = this.http.post(`${environment.api.prefix}/logout`, null).subscribe();

    // Logout
    this.auth.logout();
    this.router.navigate([environment.router.login]);
  }

  refreshToken(): Observable<RefreshTokenResponse> {
    return this.http.get<RefreshTokenResponse>(`${environment.api.prefix}/auth/refreshToken`).pipe(
      switchMap((response) => RefreshTokenResponse.deserialize(response)),
      tap((resp) => {
        this.auth.refreshToken(resp.token);
      })
    );
  }

  ngOnDestroy(): void {
    this.logoutSubscription?.unsubscribe();
  }
}
