import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {environment} from '../../../../../environments/environment';
import {LoginService} from '../../services/login.service';
import {Subscription} from 'rxjs';
import {ToastSyncfusionService} from "@tlgpro/toast-syncfusion";
import {Oauth2Service} from "../service/oauth2.service";
import {switchMap} from "rxjs/operators";

@Component({
  template: `
    <div [ngClass]="backgroundClass" class="bg-no-repeat bg-cover h-screen w-screen flex  justify-center content-center items-center">
        <div class="loader"></div>
    </div>
  `,
  styleUrls: ['./login-o-auth2.page.css']
})
// tslint:disable-next-line:component-class-suffix
export class LoginOAuth2Page implements OnInit, AfterViewInit, OnDestroy {

  backgroundClass: string;
  provider: string;

  private loginSubscription: Subscription;

  constructor(private login: LoginService,
              private oauth2Service: Oauth2Service,
              private route: ActivatedRoute,
              private router: Router,
              private toast: ToastSyncfusionService) {
  }

  ngOnInit(): void {

    this.backgroundClass = `bg-${Math.floor(Math.random() * 5) + 1}`;
    this.provider = this.route.snapshot.params.provider;
    console.log(this.provider);

  }

  ngAfterViewInit(): void {

    if(!this.provider || this.provider === "") {
      this.showErreur('Erreur: Le provider est invalide.');
    }

    if(this.provider) {
       this.oauth2Service.getJwtOAuth(this.provider, this.route.snapshot.queryParams)
         .pipe(
           switchMap(response => this.login.loginSuccessHandler(response))
         ).subscribe(() => {
         this.router.navigate([environment.router.home]);
       }, (e) => {
         this.showErreur(e);
         this.router.navigate([environment.router.home]);

         throw e;
       });
    }
  }

  showErreur(content: string) {
    this.toast.show({
      title: `Error`,
      cssClass: 'e-toast-danger',
      content: content
    });
  }
  showMessage(content: string) {
    this.toast.show({
      title: `Succès`,
      cssClass: 'e-toast-success',
      content: content
    });
  }

  ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
  }

}
