import {User} from '@tlgpro/security';
import {INavDataFilter, SecureFilter} from '@core/tools';
import {INavData} from '@tlgpro/layout/lib/models';

export abstract class Navigation {
  private static sidebarNav: INavData[] = [

    {
      name: $localize`:@@sidebar.menu.admin.status:Statuts`,
      url: '/statuts',
      icon: 'fas fa-signal',
      authorities: ['status']
    },
    {
      name: $localize`:@@sidebar.menu.admin.realtime:Temps réel`,
      url: '/realtime',
      icon: 'fas fa-tachometer-alt',
      authorities: ['realtime']
    },
    {
      name: $localize`:@@sidebar.menu.admin.services:Services`,
      url: '/services',
      icon: 'fas fa-tools',
      authorities: ['service']
    },
    {
      name: $localize`:@@sidebar.menu.admin.alarms:Alarmes`,
      url: '/alarms',
      icon: 'fa-solid fa-bell',
      authorities: ['alarm']
    },
    {
      name: $localize`:@@sidebar.menu.admin.metrics:Métriques`,
      url: '/metrics',
      icon: 'fas fa-tachometer-alt',
      authorities: ['metric']
    },
    {
      name: $localize`:@@sidebar.menu.templates.title:Modèles`,
      icon: 'fa-solid fa-puzzle-piece',
      url: '/templates',
      authorities: ['templates'],
      children: [
        {
          name: $localize`:@@sidebar.menu.templates.metric:Métrique`,
          icon: 'fas fa-tachometer-alt',
          url: '/templates/metrics',
          authorities: ['templates.metrics'],
          children: [
            {
              name: $localize`:@@sidebar.menu.templates.metric.add-template:Instancier un modèle`,
              url: '/templates/metrics/add-template',
              icon: 'fas fa-plus',
              authorities: ['templates.metrics.add-template']
            },
            {
              name: $localize`:@@sidebar.menu.templates.metric.add-file:Importer un fichier`,
              url: '/templates/metrics/import-file',
              icon: 'fas fa-file',

              authorities: ['templates.metrics.import-file']
            }
          ]
        },
        {
          name: $localize`:@@sidebar.menu.templates.alarm:Alarme`,
          icon: 'fa-solid fa-bell',
          url: '/templates/alarms',
          authorities: ['templates.alarms'],
          children: [
            {
              name: $localize`:@@sidebar.menu.templates.alarm.add-template:Instancier un modèle`,
              url: '/templates/alarms/add-template',
              icon: 'fas fa-plus',
              authorities: ['templates.alarms.add-template']
            },
            {
              name: $localize`:@@sidebar.menu.templates.alarm.add-file:Importer un fichier`,
              url: '/templates/alarms/import-file',
              icon: 'fas fa-file',
              authorities: ['templates.alarms.import-file']
            }
          ]
        },
      ]
    },
    {
      name: $localize`:@@sidebar.menu.admin.security:Sécurité`,
      icon: 'fas fa-lock',
      url: '/administration/security',
      authorities: ['security'],
      children: [
        {
          name: $localize`:@@sidebar.menu.admin.security.users:Utilisateurs`,
              url: '/administration/security/users',
              icon: 'fas fa-users-cog',
              authorities: ['security.user']
            },
            {
              name: $localize`:@@sidebar.menu.admin.security.roles:Rôles`,
              url: '/administration/security/roles',
              icon: 'fas fa-users',
              authorities: ['security.role']
            },
            {
              name: $localize`:@@sidebar.menu.admin.security.apiKey:Clés API`,
              url: '/administration/security/apiKeys',
              icon: 'fa-solid fa-key',
              authorities: ['security.apiKey']
            }
          ]
        }
      ];

  public static get(user: User | null): INavData[] {
    return SecureFilter.apply(INavDataFilter, this.sidebarNav, user);
  }

  public static add(item: INavData, callback: () => void, index = -1) {
    const url: string = item.url as string;
    if (!url) {
      console.warn('Aucune URL fournie pour l\'ajout manuel d\'un élément de navigation');
      return;
    }
    const parentUrl = url.split('/').slice(0, -1).join('/');

    const parent = Navigation.getTarget(parentUrl);

    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }

      if (parent.children.some((existing) => existing.url === item.url)) {
        callback();
        return;
      }

      if (index === -1) {
        parent.children.push(item);
      } else {
        parent.children = parent.children.splice(index, 0, item);
      }

      callback();
    }
  }

  private static getTarget(url: string, items = Navigation.sidebarNav): INavData | null {
    let target: INavData | null = null;
    for (const item of items) {
      if (item.url === url) {
        target = item;
      } else if (item?.children) {
        target = Navigation.getTarget(url, item?.children);
      }
      if (target) {
        break;
      }
    }

    return target;
  }

  public static changeIcon(url: string, newIcon: string): void {
    const target = Navigation.getTarget(url);
    if (target) {
      target.icon = newIcon;
    }
  }

  public static getChildren(url: string): INavData[] | undefined {
    return Navigation.getTarget(url)?.children;
  }
}
