import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ToastSyncfusionService} from '@tlgpro/toast-syncfusion';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {EnvironmentData} from '../models/tools/environment-data';
import {LogServiceInterface} from '../models/log-service.interface';
import {OAuthUser} from "../models/o-auth-user";


@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  constructor(
    private http: HttpClient,
    private toast: ToastSyncfusionService,
    @Inject('log') private log: LogServiceInterface,
    @Inject('env') private environment: EnvironmentData
  ) {
  }

  /**
   * Update Password
   */
  updatePassword(data: { idUser: number, password: string | undefined }): Observable<unknown> {
    if (!data.password) {
      const errMsg = $localize`Le nouveau mot de passe ne peut pas être vide`;
      this.log.getLogger('administration').error(errMsg);
      this.toast.show({
        content: $localize`Erreur lors de la modification du mot de passe`,
      });
      return throwError(errMsg);
    }

    return this.http.post<unknown>(`${this.environment.api.prefix}/user/update-password`, data).pipe(
      catchError((error) => {
        this.log.getLogger('administration').error(error);
        this.toast.show({
          content: $localize`Erreur lors de la mise à jour de la base de données`,
        });
        return throwError(error);
      })
    );
  }

  findOAuthCredentiels(idUser: number) {
    return this.http.get<unknown>(`${this.environment.api.prefix}/user/find-oauth-credentials/${idUser}`, {});
  }

  updateSSO(data: OAuthUser | undefined ): Observable<unknown> {
    if (!data) {
      const errMsg = $localize`Le nouveau OAuthUser ne peut pas être vide`;
      this.log.getLogger('administration').error(errMsg);
      this.toast.show({
        content: $localize`Erreur lors de la modification des identitiants SSO`,
      });
      return throwError(errMsg);
    }

    return this.http.post<unknown>(`${this.environment.api.prefix}/user/update-sso`, data).pipe(
      catchError((error) => {
        this.log.getLogger('administration').error(error);
        this.toast.show({
          content: $localize`Erreur lors de la mise à jour des identifiants SSO`,
        });
        return throwError(error);
      })
    );
  }

  // public getOAuthProviders(): Observable<any> {
  //   return this.http.get<any>(`${this.environment.api.prefix}/actuator/oauth2`, {})
  //     .pipe(
  //       catchError((e) => {
  //         console.log('Error catched locally', e);
  //         return of(false);
  //       })
  //   );
  // }

  async getOAuthProviders() {
    const url = `${this.environment.api.prefix}/actuator/oauth2`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        // throw new Error(`Response status: ${response.status}`);
        return null;
      }
      return response ? response?.json() : null;
    } catch (error) {
      // console.error(error.message);
    }
  }


}
